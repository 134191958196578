<template>
	<div>
		<div class="recycle_nav">
			<el-card>
				<div class="global-search-box">
					<div class="search-content">
						<div class="search-item">
							<el-input :placeholder="shopnamesku == 1 ? '请输入商品名称' : '请输入商品SKU编号'" v-model="goodsName">
								<template slot="prepend">
									<el-select v-model="shopnamesku" placeholder="请选择">
										<el-option v-for="(item, index) in shopaddList" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<label class="search-label">商品类型：</label>
							<el-select v-model="typevalue" filterable placeholder="全部">
								<el-option v-for="item in common_info['goods.vr_type.list']" :key="item.key"
									:label="item.value" :value="item.key">
								</el-option>
							</el-select>
						</div>
						<div class="search-item">
							<label class="search-label">商品分类：</label>
							<el-cascader placeholder="请选择分类" v-model="catvalue" :options="catlist" filterable clearable
								:props="{ value: 'id', label: 'label', children: 'children' }">
							</el-cascader>
						</div>
						<div class="search-item">
							<label class="search-label">商品分组：</label>
							<el-select v-model="groupvalue" filterable placeholder="全部">
								<el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="search-item">
							<label class="search-label">商品销量：</label>
							<div class="number-of-intervals">
								<el-input-number :min="minumber" :max="100000000" v-model="low_sales" :controls="false">
								</el-input-number>

								<span>-</span>
								<el-input-number type="number" :min="minumber" :max="100000000" v-model="high_sales"
									:controls="false">
								</el-input-number>
							</div>
						</div>
						<div class="search-item">
							<label class="search-label">商品价格：</label>
							<div class="number-of-intervals">
								<el-input-number type="number" :min="minumber" :max="99999999.99" v-model="low_price"
									:controls="false">
								</el-input-number>
								<span>-</span>
								<el-input-number type="number" :min="minumber" :max="99999999.99" v-model="high_price"
									:controls="false">
								</el-input-number>
							</div>
						</div>
					</div>
					<div class="serach-buttom-button">
						<el-button type="primary" @click="recycelsearch">搜索</el-button>
						<el-button hidefocus="hidefocus" @click="resetting">重置条件</el-button>
					</div>
				</div>
			</el-card>
		</div>
		<div class="recycle_content">
			<div class="recycle_card">
				<div class="recycle_table goods_table" ref="goods_table">
					<div class="table_head addtable_head d-flex align-items-center">
						<el-checkbox v-model="all_checked" @change="allchange"></el-checkbox>
						<p class="page_num">当前页全选</p>
						<el-button class="grounding" @click="allrestore" v-if="hasPerm(['goods.recycle.restore'])">恢复
						</el-button>
						<el-button class="grounding" @click="alldelect" v-if="hasPerm(['goods.recycle.delete'])">删除
						</el-button>
					</div>
					<div class="recyclelist">
						<el-table :data="tableData" style="width: 100%" ref="multipleTable" @selection-change="singletable">
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column width="100" prop="id" label="ID"></el-table-column>
							<el-table-column label="商品" width="580">
								<template slot-scope="scope">
									<div class="goods_detail d-flex align-items-center">
										<div class="goods_img">
											<img :src="scope.row.cover_pic" alt="" :onerror="errorImg" />
										</div>
										<div class="goods-detail">
											<p class="show-one-lines">{{ scope.row.name }}</p>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="售价" prop="price" min-width="150">
							</el-table-column>
							<el-table-column label="库存" prop="stock" min-width="150">
							</el-table-column>
							<el-table-column label="销量" prop="virtual_sales" min-width="150">
							</el-table-column>
							<el-table-column label="排序" prop="sort" width="100">
							</el-table-column>
							<el-table-column label="操作" fixed="right" width="200">
								<template slot-scope="scope">
									<div class="caozuo">
										<div @click="restore(scope.row.id)" v-if="hasPerm(['goods.recycle.restore'])">
											恢复
										</div>
										<div @click="recycledelete(scope.row.id)" v-if="hasPerm(['goods.recycle.delete'])">
											删除
										</div>
									</div>
								</template>
							</el-table-column>

							<template slot="empty">
								<div class="empty_data">
									<p>暂无数据</p>
								</div>
							</template>
						</el-table>
					</div>
				</div>

				<div class="table_foot" style="padding-left: 0">
					<div class="foot_left addfoot_left d-flex align-items-center">
						<el-checkbox v-model="all_checked" @change="allchange"></el-checkbox>
						<p class="page_num">共{{ pagesNumber }}条</p>
						<el-button class="grounding" @click="allrestore" v-if="hasPerm(['goods.recycle.restore'])">恢复
						</el-button>
						<el-button class="grounding" @click="alldelect" v-if="hasPerm(['goods.recycle.delete'])">删除
						</el-button>
					</div>
					<div class="main_pages">
						<el-pagination @size-change="handleSizeChange" hide-on-single-page
							@current-change="handleCurrentChange" :current-page.sync="currentPage"
							:page-sizes="[10, 20, 30, 40]" :page-size="eachPage" layout="sizes, prev, pager, next, jumper"
							:pager-count="5" :total="total_entry">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	inject: ["reload"],
	data() {
		return {
			errorImg: 'this.src="' + require("../../assets/images/lodingsb.svg") + '"', //图片加载失败
			goodsName: "",
			typevalue: "", //商品类型
			low_sales: 0, //低销量
			high_sales: 0, //高销量
			low_price: 0, //低价
			high_price: 0, //高价
			is_advanced: false, //是否开启高级筛选
			all_checked: false, //全选
			currentPage: 1,
			eachPage: 10,
			total_entry: 0,
			multipleSelection: [], //表格中选中的数据
			pagesNumber: 0,
			tableData: [],
			idlist: [],
			minumber: null,
			catvalue: "",
			catlist: [], //商品分类列表
			groupvalue: "",
			grouplist: [], //商品分组
			shopnamesku: '1',
			shopaddList: [{
				label: "商品名称",
				value: "1",
			},
			{
				label: "商品SKU",
				value: "2",
			}],
			common_info: {}
		};
	},
	created() {
		let data = {
			keys: ["goods.vr_type.list"],
		};
		this.common.getCommonInfo(this, data, res => {
			this.common_info = res;
		});
	},
	mounted: function() {
		this.recyclelistapi();
		this.argumentCatlist();
		this.grouplistapi();
	},
	methods: {
		recyclelistapi() {
			let data = {
				search: [],
				order: [{
					key: "id",
					value: "desc",
				},],
				page: this.currentPage,
				limit: this.eachPage,
			};
			if (this.goodsName) {
				let obj = {
					key: this.shopnamesku == 1 ? "name" : "sku",
					value: this.goodsName,
					op: "search",
				};
				data.search.push(obj);
			}
			if (this.typevalue) {
				data.search.push({
					key: "vr_type",
					value: this.typevalue,
					op: "equal",
				});
			}
			if (this.catvalue) {
				data.search.push({
					key: "cat_id",
					value: this.catvalue[this.catvalue.length - 1],
					op: "equal",
				});
			}
			if (this.groupvalue) {
				data.search.push({
					key: "group",
					value: this.groupvalue,
					op: "equal"
				});
			}
			if (this.low_sales && this.high_sales) {
				let pricelist = [];
				if (Number(this.high_sales) <= Number(this.low_sales)) {
					this.$message({
						showClose: true,
						message: "商品销量区间输入有误",
						type: "warning",
						offset: 200,
						duration: 1000,
					});
					return false;
				}
				pricelist.push(this.low_sales, this.high_sales);
				data.search.push({
					key: "virtual_sales",
					value: pricelist,
					op: "between",
				});
			}
			if (this.low_price && this.high_price) {
				let pricelist = [];
				if (Number(this.high_price) <= Number(this.low_price)) {
					this.$message({
						showClose: true,
						message: "商品价格区间输入有误",
						type: "warning",
						offset: 200,
						duration: 1000,
					});
					return false;
				}
				pricelist.push(this.low_price, this.high_price);
				data.search.push({
					key: "virtual_sales",
					value: pricelist,
					op: "between",
				});
			}
			this.$get(this.$apis.recycle, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pagesNumber = res.data.total;
						this.total_entry = res.data.total;
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},

		// 获取商品分类
		argumentCatlist() {
			this.$get(this.$apis.catList)
				.then((res) => {
					if (res.code == 200) {
						this.catlist = this.getTreeData(res.data.list);
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		getTreeData(data) {
			for (var i = 0; i < data.length; i++) {
				if (data[i].children.length < 1) {
					data[i].children = undefined;
				} else {
					this.getTreeData(data[i].children);
				}
			}
			return data;
		},
		// 获取分组
		grouplistapi() {
			let data = {
				page: 1,
				limit: 100,
			};
			this.$get(this.$apis.groupList, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.grouplist = res.data.list;
					} else {
						this.common.message(this, res.message);
					}
					// this.tableloading = false;
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},

		// 批量恢复
		allrestore() {
			this.idlist = [];
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: "请选择需要恢复的商品",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.idlist.push(this.multipleSelection[i].id);
			}
			this.restoreapi(this.idlist);
		},
		restore(id) {
			this.idlist = [];
			this.idlist.push(id);
			this.restoreapi(this.idlist);
		},
		// 恢复接口
		restoreapi(id) {
			let data = {
				id: id,
			};
			this.$confirm("您确定恢复该商品?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$put(this.$apis.recycleRestore, data)
						.then((res) => {
							// //console.log(res)
							if (res.code == 200) {
								this.$message({
									type: "success",
									duration: 1500,
									message: "恢复成功",
									onClose: () => {
										this.recyclelistapi();
										this.$refs.multipleTable.clearSelection();
										this.all_checked = false;
									},
								});
							} else {
								this.common.message(this, res.message);
							}
						})
						.catch((err) => {
							// //console.log(err)
							this.common.message(this, err.message);
						});
				})
				.catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消删除'
					// });
				});
		},
		// 批量删除
		alldelect() {
			this.idlist = [];
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: "请选择需要删除的商品",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.idlist.push(this.multipleSelection[i].id);
			}
			this.restoredelectapi(this.idlist);
		},
		recycledelete(id) {
			this.idlist = [];
			this.idlist.push(id);
			this.restoredelectapi(this.idlist);
		},
		// 删除接口
		restoredelectapi(id) {
			let data = {
				id: id,
			};
			this.$confirm("您确定删除该商品?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$delete(this.$apis.recycleDelete, data)
						.then((res) => {
							// //console.log(res)
							if (res.code == 200) {
								this.$message({
									type: "success",
									duration: 1500,
									message: "删除成功",
									onClose: () => {
										this.recyclelistapi();
										this.$refs.multipleTable.clearSelection();
										this.all_checked = false;
									},
								});
							} else {
								this.common.message(this, res.message);
							}
						})
						.catch((err) => {
							// //console.log(err)
							this.common.message(this, err.message);
						});
				})
				.catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消删除'
					// });
				});
		},
		// 搜索
		recycelsearch() {
			this.currentPage = 1;
			this.recyclelistapi();
		},
		// 重置
		resetting() {
			this.goodsName = "";
			this.typevalue = "";
			this.catvalue = "";
			this.groupvalue = "";
			this.low_sales = "";
			this.high_sales = "";
			this.low_price = "";
			this.high_price = "";
			this.reload();
			this.recyclelistapi();
		},
		screen() {
			this.is_advanced = !this.is_advanced;
		},
		allchange(value) {
			if (!value) {
				this.$refs.multipleTable.clearSelection();
			} else {
				this.$refs.multipleTable.toggleAllSelection();
			}
			this.all_checked = value;
		},
		singletable(val) {
			this.multipleSelection = val;
			if (val.length == this.tableData.length) {
				this.all_checked = true;
			} else {
				this.all_checked = false;
			}
		},
		handleSizeChange(val) {
			this.eachPage = val;
			this.recyclelistapi();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.common.backTop();
			this.recyclelistapi();
		},
	},
};
</script>

<style scoped>
.recyclelist /deep/ .el-table th>.cell {
	padding-left: 14px;
}

.goods-label {
	color: #666;
}

.recyclelist .el-table th.el-table__cell:first-child>.cell {
	padding-left: 14px;
}

.recycle_nav {
	margin-bottom: 20px;
}

.recycle_head {
	padding-bottom: 20px;
	overflow: hidden;
	box-shadow: inset 0 -1px 0 #efefef;
}

.high_sarch {
	background: var(--fontColor);
	color: #fff;
	padding: 0 16px;
	height: 38px;
	font-size: 14px;
	line-height: 38px;
	display: inline-block;
	border-radius: 4px;
}

.recycle_card {
	padding: 20px;
	background-color: #fff;
}

.table_head {
	margin-bottom: 10px;
}

.addtable_head {
	padding-left: 14px;
	box-sizing: border-box;
}

.goods_table table th,
table td {
	border: none;
}

.goods_table .el-table .el-table__header-wrapper .el-table__header thead tr th {
	background-color: #f9f9fc;
}

.goods_table .el-table .el-table__header-wrapper {
	background-color: #f9f9fc;
}

.goods_table .el-table .el-table__fixed .el-table__fixed-header-wrapper {
	background-color: #f9f9fc;
}

.el-table th.el-table__cell {
	background-color: #f9f9fc;
	font-weight: 600;
	color: #333;
	padding: 8px 0;
}

.good_price .cell {
	text-align: center;
}

.goods_img {
	width: 50px;
	height: 50px;
	min-width: 50px;
}

.goods_img img {
	width: 100%;
	height: 100%;
}

.goods_detail {
	width: 100%;
}

.goods_detail:hover .goodcompile {
	display: block;
}

.goodcompile:active {
	display: block;
}

.goods-detail {
	flex: 1;
	flex: 1 1 0%;
	margin-left: 5px;
}

.goods-detail p:first-child {
	max-width: 450px;
}

.goods-type {
	margin-right: 5px;
	margin-top: 4px;
	border-radius: 2px;
	color: var(--fontColor, #fb6638);
	background-color: #eaebf6;
	height: 20px;
	line-height: 20px;
	padding: 0 5px;
	display: inline;
	cursor: pointer;
}

.goods-type:hover {
	background-color: transparent;
}

.caozuo div {
	padding: 0 10px;
	border-right: 1px solid var(--fontColor, #fb6638);
	cursor: pointer;
	display: inline-block;
	line-height: 1;
	color: var(--fontColor);
}

.caozuo div:last-child {
	border-right: none;
}

.empty_data {
	width: 100%;
	padding: 10px 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.empty_data p {
	margin-top: 16px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.4);
	line-height: 20px;
}

.table_foot {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.addfoot_left {
	padding-left: 14px;
	box-sizing: border-box;
}

.grounding {
	height: 32px;
	line-height: 32px;
	padding-top: 0;
	padding-bottom: 0;
	margin-left: 8px;
	padding: 12px 10px !important;
	line-height: 0;
}

.grounding:focus {
	background: #fff;
	border: 1px solid #dcdfe6;
	color: #606266;
}

.foot_cascader {
	margin-left: 10px;
	min-width: 112px;
	height: 32px;
}

.foot_cascader .el-input {
	height: 32px;
}

.foot_cascader .el-input input {
	height: 32px;
}

.foot_cascader .el-input--suffix {
	line-height: 32px;
	height: 32px;
	display: block;
}

.foot_cascader .el-input--suffix .el-input__suffix .el-input__suffix-inner .el-input__icon {
	line-height: 32px;
}

.page_num {
	margin-left: 12px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #7d7e80;
	line-height: 21px;
}

.main_pages {
	display: flex;
	align-items: center;
}

.main_pages .el-pagination {
	display: flex;
	align-items: center;
}

.main_pages .el-pagination .el-pager {
	display: flex;
	align-items: center;
}

.main_pages .el-pagination .el-pager .number {
	min-width: 32px;
	height: 32px !important;
	line-height: 32px !important;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #dcdee0;
	margin-left: 4px;
}

.main_pages .el-pagination .el-pager .number.active {
	color: #fff !important;
	background: var(--fontColor);
	border-color: var(--fontColor, #fb6638) !important;
}

.main_pages .el-pagination .el-pager .number:first-child {
	margin-left: 0;
}

/* 商品详情 */
nav {
	background: #fff;
	border-radius: 4px;
	position: relative;
	transition: all 0.2s ease-in-out;
	flex: 1 1 auto;
	padding-bottom: 65px;
}

.detail_warp>.el-tabs>.el-tabs__header {
	padding: 0 30px;
}

.basics_wrap {
	padding: 10px 24px;
	position: relative;
	min-height: 600px;
	flex-shrink: 0;
	width: 100%;
	transition: opacity 0.3s;
	opacity: 1;
	outline: none;
}

.detail_warp .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav>.el-tabs__item {
	padding: 16px;
	padding-left: 16px;
	height: auto;
	line-height: normal;
	color: #666;
}

.detail_warp .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav>.is-active {
	font-weight: 600;
	color: var(--fontColor);
}

.detail_warp .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav .el-tabs__active-bar {
	background-color: var(--fontColor);
	/* min-width: 60px; */
}

.detail_warp>.el-tabs>.el-tabs__content>.el-tab-pane {
	padding: 10px 24px;
	position: relative;
	min-height: 600px;
	flex-shrink: 0;
	width: 100%;
	transition: opacity 0.3s;
	opacity: 1;
	outline: none;
}

.basics_wrap>.el-form>.el-form-item>.el-form-item__label {
	color: #999;
	padding-right: 20px;
}

.detail_photo {
	width: 100px;
	height: 100px;
	border-radius: 4px;
	margin-right: 20px;
	border: 1px solid #ededed;
}

.detail_photo img {
	width: 100%;
	height: 100%;
}

.detail_video {
	width: 260px;
	height: 150px;
}

.detail_video video {
	width: 100%;
	height: 100%;
}

.detail_good {
	width: 417px;
	height: auto;
	border: 1px solid #ddd;
	border-radius: 4px;
	background: #fafafa;
	padding: 20px;
	position: relative;
}

footer {
	position: fixed;
	left: 160px;
	right: 0;
	bottom: 0;
	min-width: 980px;
	margin: 0 24px;
	height: auto;
	border-top: 1px solid #f1f1f1;
	z-index: 100;
	background-color: #fff;
}

.class_foot {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.attr_foot_cancel {
	border: 1px solid transparent;
	color: #666;
	background-color: #fff;
	border-color: #ededed;
}
</style>
<style lang="scss" scoped>
.search_headname {
	height: 40px;

	/deep/.el-input {
		.el-input-group__prepend {
			padding: 0;

			.orderfirstleft {
				.el-select {
					margin: 0;

					.el-input {
						.el-input__inner {
							height: 38px;
							line-height: 38px;
						}
					}
				}
			}
		}
	}
}

.searchGood_name {
	min-width: 384px;
}

.searchGood_name .addinputname {
	width: 274px;
}

/deep/.searchGood_name .addinputname .el-input__inner {
	border-radius: 0 4px 4px 0;
}

/deep/.searchGood_name .el-select .el-input__inner {
	height: 40px !important;
	border-radius: 4px 0 0 4px;
}

/deep/.searchGood_name .el-select .el-input__inner:focus {
	border-radius: 4px 0 0 4px !important;
}
</style>
